import { useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { GlobalContextState, GlobalContextDispatch } from "./context/globalContext";
import "../src/styles.css";
import Home from "./pages/Home";
import Dashboard from './pages/Dashboard';
import Document from './pages/Document';
import Settings from './pages/Settings';
import Intergrations from './pages/Intergrations';
import Claims from "./pages/Claims";
import BodyShopRegister from './pages/BodyShopRegister';
import ResetPassword from './pages/ResetPassword';
import DeleteAccount from './pages/DeleteAccount';
import Logout from "./pages/Logout";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ChatForm from "./pages/ChatForm";
import { baseUrl } from "./config";

function NotFound() {
  return <h1>Not Found</h1>;
}

function PrivateRoute({ children }) {
  const { loggedIn } = useContext(GlobalContextState);
  return loggedIn ? children : <Redirect to="/" />;
}

function PrivateAndSubscribedRoute({ children }) {
  const { loggedIn, user } = useContext(GlobalContextState);
  if(loggedIn) {
    if(user.subscription) {
      return children;
    } else {
      return <Redirect to="/settings/subscription" />;
    }
  }else {
    return <Redirect to="/" />;
  }
}

// Back to Top Button Component
function BackToTopButton() {
  const [isVisible, setIsVisible] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const toggleVisibility = () => {
    if (window.scrollY > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  return (
    isVisible && (
      <button
        onClick={scrollToTop}
        className="fixed bottom-5 right-5 bg-blue-500 text-white rounded-lg p-3 shadow-lg hover:bg-blue-700 transition-all"
        aria-label="Back to top"
      >
        ↑
      </button>
    )
  );
}

function EmailConfirmation() {
  const dispatch = useContext(GlobalContextDispatch);
  const urlParams = new URLSearchParams(window.location.search);
  const confirmation = urlParams.get('confirmation');
  
  const serverConfirmationUrl = `${baseUrl}/api/auth/email-confirmation?confirmation=${confirmation}`;
  
  let myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  fetch(serverConfirmationUrl, {
    method: 'GET',
    headers: myHeaders
  })
  .then(response => {
    if (!response.ok) {
      window.location.href = "/email-confirmation-false";
    }
    // Parse the JSON body
    return response.json();
  })
  .then(data => {
    console.log('data', data);
    const { jwt, user } = data;

    if (jwt && user) {
      dispatch({ type: "LOGIN", payload: { jwt, user } });
      window.location.href = "/settings/subscription?email-confirmed=done";
    } else {
      // If the JWT or user is not present, handle error
      throw new Error('Invalid response data');
    }
  })
  .catch(error => {
    console.error('error', error);
    // Redirect to an error page or show an error message
    window.location.href = "/email-confirmation-false";
  });
}

function EmailConfirmationFalse() {
  return (
    <div className="flex justify-center items-center min-h-screen text-center">
      <div className="max-w-xl p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
        <svg
          className="w-12 h-12 text-white mb-3 mx-auto"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            fillRule="evenodd"
            d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4a1 1 0 1 0-2 0v5a1 1 0 1 0 2 0V8Zm-1 7a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2H12Z"
            clipRule="evenodd"
          />
        </svg>

        <h5
          id="notification-title"
          className="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white"
        >
          Email Verification Failed
        </h5>
        <p id="notification-text" className="mb-3 font-normal text-gray-500 dark:text-gray-400 leading-6">
          Your email was already confirmed, please try to <a className="text-white underline" href="https://app.trueclaim.ai/">logging in</a>.<br/> If you are incurring issues, please <a className="text-white underline" href="mailto:contact@trueclaim.ai">let us know</a>.
        </p>
        <div className="flex space-x-4 text-white justify-center mt-8">
          <a
            href="mailto:contact@trueclaim.ai"
            className="hover:underline flex justify-center items-center mx-4"
          >
            <svg
              className="w-6 h-6 text-gray-800 dark:text-white mr-2"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeWidth="2"
                d="m3.5 5.5 7.893 6.036a1 1 0 0 0 1.214 0L20.5 5.5M4 19h16a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1Z"
              />
            </svg>
            contact@trueclaim.ai
          </a>
          <a
            href="tel:+15143176780"
            className="hover:underline flex justify-center items-center mx-4"
          >
            <svg
              className="w-6 h-6 text-gray-800 dark:text-white mr-2"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M18.427 14.768 17.2 13.542a1.733 1.733 0 0 0-2.45 0l-.613.613a1.732 1.732 0 0 1-2.45 0l-1.838-1.84a1.735 1.735 0 0 1 0-2.452l.612-.613a1.735 1.735 0 0 0 0-2.452L9.237 5.572a1.6 1.6 0 0 0-2.45 0c-3.223 3.2-1.702 6.896 1.519 10.117 3.22 3.221 6.914 4.745 10.12 1.535a1.601 1.601 0 0 0 0-2.456Z"
              />
            </svg>
            514-317-6780
          </a>
        </div>
      </div>
    </div>
  )
}

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" render={() => <Home />} />
        <Route path="/body-shop-registration" render={() => <BodyShopRegister />} />
        <Route path="/email-confirmation" render={() => <EmailConfirmation />} />
        <Route path="/email-confirmation-false" render={() => <EmailConfirmationFalse />} />
        <Route path="/reset-password" render={() => <ResetPassword />} />
        <Route path="/chat" render={() => <ChatForm />} />
        <Route path="/settings">
          <PrivateRoute>
            <Settings />
          </PrivateRoute>
        </Route>
        <Route path="/intergrations">
          <PrivateRoute>
            <Intergrations />
          </PrivateRoute>
        </Route>
        <Route path="/request-delete-account">
          <PrivateRoute>
            <DeleteAccount />
          </PrivateRoute>
        </Route>
        <Route path="/dashboard">
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        </Route>
        <Route path="/documents">
          <PrivateRoute>
            <Document />
          </PrivateRoute>
        </Route>
        <Route path="/claims">
          <PrivateAndSubscribedRoute>
            <Claims />
          </PrivateAndSubscribedRoute>
        </Route>
        <Route path="/privacy-policy" render={() => <PrivacyPolicy />} />
        <Route path="/logout" render={() => <Logout />} />
        <Route path="*" render={() => <NotFound />} />
      </Switch>

      <BackToTopButton />
    </Router>
  );
}

export default App;