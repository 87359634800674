import React, { useState, useContext, useEffect } from "react";
import { GlobalContextState } from "../../context/globalContext";
import { GlobalContextDispatch } from "../../context/globalContext";
import { baseUrl } from "../../config";
import { Button, GridItem, Grid } from "@strapi/design-system";
import { Check } from '@strapi/icons';

const INITIAL_FORM_ERRORS = {
  firstName: false,
  lastName: false,
  company: false
};

export default function UpdateProfile() {
  const dispatch = useContext(GlobalContextDispatch);
  const [status, setStatus] = useState(undefined);
  const [formError, setFormError] = useState(INITIAL_FORM_ERRORS);
  const user = useContext(GlobalContextState).user;
  const token = useContext(GlobalContextState).token;
  const userFullName = user.firstName + " " + user.lastName;
  const userUrl = `${baseUrl}/api/users/${user.id}`;
  const [loadingSubmit, setLoadingSubmit] = React.useState(false);
  const [logoUrl, setLogoUrl] = useState(null);
  const [logo, setLogo] = useState(null);

  const [formData, setFormData] = useState({
    firstName: user.firstName,
    lastName: user.lastName,
    company: user.company
  });

  useEffect(() => {
    async function getUserLogo() {
      try {
        const response = await fetch(`${baseUrl}/api/tc-app/get-user-logo/${user.id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        setLogoUrl(data.url);
      } catch (error) {
        console.log(error);
      }
    }

    getUserLogo();
  }, []);

  async function handleUpdateLogo(event) {
    const { files } = event.target;
    const uploadUrl = `${baseUrl}/api/tc-app/update-user-logo`;
  
    if (files.length > 0) {
      setLogoUrl(URL.createObjectURL(files[0]));
      let formDataToSubmit = new FormData();
      formDataToSubmit.append("logo", files[0]);
  
      let requestOptions = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formDataToSubmit, // Send FormData directly
      };
  
      const response = await fetch(uploadUrl, requestOptions);
      const data = await response.json();
      console.log(data);
    }
  }

  function handleInputChange(event) {
    const { name, value } = event.target;
    if (value !== "") {
      setFormData({ ...formData, [name]: value });
    } else {
      setFormData({ ...formData, [name]: '' });
    }
  }

  function validateFormData(){
    let hasError = false;

    if(formData.firstName === '') {
      hasError = true;
      setFormError((prevState) => ({ ...prevState, firstName: true }));
    }else {
      setFormError((prevState) => ({ ...prevState, firstName: false }));
    }
    if(formData.lastName === '') {
      hasError = true;
      setFormError((prevState) => ({ ...prevState, lastName: true }));
    }else {
      setFormError((prevState) => ({ ...prevState, lastName: false }));
    }
    if(formData.company === '') {
      hasError = true;
      setFormError((prevState) => ({ ...prevState, company: true }));
    }else {
      setFormError((prevState) => ({ ...prevState, company: false }));
    }

    return hasError;
  }
  
  async function handleSubmit(event) {
    event.preventDefault();
    let hasError = validateFormData();
    if(!hasError) {
      setLoadingSubmit(true);
      try {
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${token}`);

        var requestOptions = {
          method: "PUT",
          headers: myHeaders,
          body: JSON.stringify(formData),
        };

        fetch(userUrl, requestOptions)
          .then((response) => {
            return response.json();
          })
          .then(function (data) {
            if (data && data.hasOwnProperty("error")) {
              let message = data.error.message;
              setStatus({ type: "error", message: message });
            } else {
              let message = "Your information has been updated.";
              setStatus({ type: "success", message: message });
              dispatch({ type: "LOGIN", payload: { jwt: token, user: data } });
            }
            setLoadingSubmit(false);
          })
          .catch((error) => {
            setStatus({ type: "error", message: error });
            setLoadingSubmit(false);
          });
      } catch (error) {
        setStatus({ type: "error", message: error });
        setLoadingSubmit(false);
      }
    }
  }

  async function handleDeleteLogo() {
    try {
      const response = await fetch(`${baseUrl}/api/tc-app/delete-user-logo/${user.id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setLogoUrl(null);
    } catch (error) {
      console.log(error);
    }
  }
  
  return (
    <>
      <div className="px-14 flex justify-between items-center pt-8">
        <div>
          <h2 className="text-2xl font-bold text-white text-3xl">{userFullName}</h2>
        </div>
        <div>
          <Button
            onClick={handleSubmit}
            startIcon={<Check />}
            loading={loadingSubmit && true}
            className="button-new-claim"
          >
            Save Change
          </Button>
        </div>
      </div>
      <div className="px-14 py-4">
        <div className="rounded bg-gray-800 p-4">
          {status?.type === "success" && (
            <div className="p-4 mb-4 text-sm text-green-800 rounded-lg bg-green-50 dark:bg-gray-700 dark:text-green-400" role="alert">
              <span className="font-medium">{status?.message}</span>
            </div>
          )}
          {status?.type === "error" && (
            <div className="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-700 dark:text-red-400" role="alert">
              <span className="font-medium">{status?.message}</span>
            </div>
          )}

          <Grid gap={5}>
            <GridItem col={6}>
              <label className="block text-sm text-gray-400 mb-2">Email</label>
              <input
                className="bg-gray-800 w-full rounded-md p-2 text-white"
                label="Email"
                name="email"
                value={user.email}
                type="email"
                disabled
              />
            </GridItem>
            <GridItem col={6}>
              <label className="block text-sm text-gray-400 mb-2">Company</label>
              <input
                className="bg-gray-800 w-full rounded-md p-2 text-white"
                label="Company"
                name="company"
                type="text"
                placeholder="Company"
                value={formData.company}
                onChange={handleInputChange}
                error={formError.company && "Please enter company name"}
              />
            </GridItem>
            <GridItem col={6}>
              <label className="block text-sm text-gray-400 mb-2">First Name</label>
              <input
                className="bg-gray-800 w-full rounded-md p-2 text-white"
                label="First Name"
                name="firstName"
                type="text"
                placeholder="First Name"
                value={formData.firstName}
                onChange={handleInputChange}
                error={formError.firstName && "Please enter first name"}
              />
            </GridItem>
            <GridItem col={6}>
              <label className="block text-sm text-gray-400 mb-2">Last Name</label>
              <input
                className="bg-gray-800 w-full rounded-md p-2 text-white"
                label="Last Name"
                name="lastName"
                type="text"
                placeholder="Last Name"
                value={formData.lastName}
                onChange={handleInputChange}
                error={formError.lastName && "Please enter last name"}
              />
            </GridItem>
            <GridItem col={6}>
              <label className="block text-sm text-gray-400 mb-2">Logo</label>
              <div className="flex items-center mb-2">
                {logoUrl && (
                  <div className="mr-4">
                    <img src={logoUrl} alt="Logo Preview" className="max-h-[90px] max-w-full h-auto w-auto" height={90} />
                  </div>
                )}
                {logoUrl && (
                  <button
                    type="button"
                    className="text-red-500 hover:text-red-700 bg-blue-50 hover:bg-blue-100 px-2 py-1 rounded-md"
                    onClick={() => handleDeleteLogo()}
                  >
                    Delete
                  </button>
                )}
              </div>
              <input
                className="bg-gray-800 w-full rounded-md p-2 text-white"
                accept="image/*"
                label="Logo"
                name="logo"
                type="file" 
                onChange={handleUpdateLogo}
              />
            </GridItem>
          </Grid>
        </div>
      </div>
    </>
  );
}
