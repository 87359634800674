import { useState, useContext, useEffect } from "react";
import { baseUrl } from '../../config';
import { GlobalContextDispatch } from "../../context/globalContext";
import useFetchMutation from '../../hooks/useFetchMutation';
import { FormImage } from "../../styled/styles/form";
import { Flex, Grid, GridItem, SingleSelect, SingleSelectOption, Typography, TextInput, Alert, Button, Box, Checkbox, Link } from "@strapi/design-system";
import { ModalLayout, ModalBody, ModalHeader } from '@strapi/design-system';
import { BlocksRenderer } from '@strapi/blocks-react-renderer';

const registerUrl = `${baseUrl}/api/auth/local/register`;

const INITIAL_FORM_DATA = {
  firstName: "",
  lastName: "",
  company: "",
  phoneNumber: "",
  email: "",
  password: "",
  confirmPassword: "",
  role: "Authenticated",
  customerJobs: "BS",
  terms: false
};

const INITIAL_FORM_ERRORS = {
  firstName: false,
  lastName: false,
  company: false,
  email: false,
  password: false,
  confirmPassword: false,
  customerJobs: false,
  terms: false
};

export default function SignupForm() {

  const dispatch = useContext(GlobalContextDispatch);

  const [registerUser, { data, loading, error} ] = useFetchMutation(registerUrl);
  const [signUpStatus, setSignUpStatus] = useState(undefined);
  const [formData, setFormData] = useState(INITIAL_FORM_DATA);
  const [formError, setFormError] = useState(INITIAL_FORM_ERRORS);
  const [isVisible, setIsVisible] = useState(false);
  const [isContentLoading, setIsContentLoading] = useState(false);
  const [termsContent, setTermsContent] = useState({});
  const [signUpEmail, setSignUpEmail] = useState("");

  useEffect(() => {
    if (error) {
      setSignUpStatus({ type: "error" });
    }

    if (data) {
      if(data.user) {
        console.log(data);
        setSignUpStatus({ type: 'success' });
        setSignUpEmail(data.user.email);
        setFormData(INITIAL_FORM_DATA);
      }
    }

    loadTerms()
  }, [data, error, dispatch]);

  function loadTerms() {
    setIsContentLoading(true);
    fetch(`${baseUrl}/api/app-configuration`)
      .then((response) => response.json())
      .then((data) => {
        setIsContentLoading(false);
        setTermsContent(data.data.attributes.termsAndConditions);
        console.log(data);
      })
      .catch((error) => {
        setIsContentLoading(false);
        console.error("Error fetching terms", error);
      });
  }

  function handleInputChange(event) {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    if(name === "firstName" && value !== "") {
      setFormError((prevState) => ({ ...prevState, firstName: false }));
    }
    if(name === "lastName" && value !== "") {
      setFormError((prevState) => ({ ...prevState, lastName: false }));
    }
    if(name === "company" && value !== "") {
      setFormError((prevState) => ({ ...prevState, company: false }));
    }
    if(name === "email" && value !== "") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (emailRegex.test(value)) {
        setFormError((prevState) => ({ ...prevState, email: false }));
      }
    }
    if(name === "password" && value !== "") {
      if(value.length > 6) {
        setFormError((prevState) => ({ ...prevState, password: false }));
      }
    }
    if(name === "confirmPassword" && value !== "") {
      if(formData.password === value) {
        setFormError((prevState) => ({ ...prevState, confirmPassword: false }));
      }
    }
    if(name === "phoneNumber" && value !== "") {
      const phoneRegex = /^(\d{10})$/;
      if (phoneRegex.test(value)) {
        setFormError((prevState) => ({ ...prevState, phoneNumber: false }));
      }
    }
  }

  function setSingleSelection(event) {
    setFormData({ ...formData, customerJobs: event });

    if(event === "CO") {
      setFormError((prevState) => ({ ...prevState, company: false }));
    }
  }

  function setTerms(event) {
    if (event.target.checked) {
      setFormData({ ...formData, terms: true });
    }else {
      setFormData({ ...formData, terms: false });
    }
  }

  function formValidation(formData) {
    let hasError = false;

    // Validate first name
    if(formData.firstName === "") {
      hasError = true;
      setFormError((prevState) => ({ ...prevState, firstName: true }));
      return hasError;
    }else {
      setFormError((prevState) => ({ ...prevState, firstName: false }));
    }

    // Validate last name
    if(formData.lastName === "") {
      hasError = true;
      setFormError((prevState) => ({ ...prevState, lastName: true }));
      return hasError;
    } else {
      setFormError((prevState) => ({ ...prevState, lastName: false }));
    }

    // Validate customerJobs
    if (formData.customerJobs === "") {
      hasError = true;
      setFormError((prevState) => ({ ...prevState, customerJobs: true }));
      return hasError;
    }else {
      setFormError((prevState) => ({ ...prevState, customerJobs: false }));
      if(formData.customerJobs !== "CO") {
        if (formData.company === "") {
          hasError = true;
          setFormError((prevState) => ({ ...prevState, company: true }));
          return hasError;
        }
      }else {
        setFormError((prevState) => ({ ...prevState, company: false }));
      }
    }

    // Validate email
    if (formData.email === "") {
      hasError = true;
      setFormError((prevState) => ({ ...prevState, email: true }));
      return hasError;
    } else {
      // validate email format by regex
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (emailRegex.test(formData.email)) {
        setFormError((prevState) => ({ ...prevState, email: false }));
      }else {
        hasError = true;
        setFormError((prevState) => ({ ...prevState, email: true }));
        return hasError;
      }
    }

    // Validate password
    if (formData.password === "") {
      hasError = true;
      setFormError((prevState) => ({ ...prevState, password: true }));
      return hasError;
    }else {
      if (formData.password.length <= 6) {
        hasError = true;
        setFormError((prevState) => ({ ...prevState, password: true }));
        return hasError;
      }else {
        setFormError((prevState) => ({ ...prevState, password: false }));
      }
    }

    // Validate confirm password
    if (formData.confirmPassword === "") {
      hasError = true;
      setFormError((prevState) => ({ ...prevState, confirmPassword: true }));
      return hasError;
    }else {
      if (formData.password !== formData.confirmPassword) {
        hasError = true;
        setFormError((prevState) => ({ ...prevState, confirmPassword: true }));
        return hasError;
      }else {
        setFormError((prevState) => ({ ...prevState, confirmPassword: false }));
      }
    }

    // Validate terms
    if (formData.terms === false) {
      hasError = true;
      setFormError((prevState) => ({ ...prevState, terms: true }));
      return hasError;
    }else {
      setFormError((prevState) => ({ ...prevState, terms: false }));
    }

    return hasError;
  }

  function hadleFormSubmit(event) {
    event.preventDefault();
    setSignUpStatus({ type: undefined });
    const hasErrors = formValidation(formData);

    if (!hasErrors) {
      formData.username = formData.email;

      const registerPayload = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      };

      registerUser(registerPayload);
    }
  }

  return (
    <>
      {signUpStatus?.type === 'success' && (
        <>
          <div id="popup-modal" tabindex="-1" className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full flex">
              <div className="relative p-4 w-full max-w-lg max-h-full">
                  <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                      <button 
                        onClick={() => setSignUpStatus({ type: undefined })}
                        type="button" className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="popup-modal">
                          <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                          </svg>
                          <span className="sr-only">Close modal</span>
                      </button>
                      <div className="p-4 md:p-5 text-center">
                          <svg className="mx-auto mb-4 text-gray-400 w-24 h-24 dark:text-green-700" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                            <path fillRule="evenodd" d="M12 2c-.791 0-1.55.314-2.11.874l-.893.893a.985.985 0 0 1-.696.288H7.04A2.984 2.984 0 0 0 4.055 7.04v1.262a.986.986 0 0 1-.288.696l-.893.893a2.984 2.984 0 0 0 0 4.22l.893.893a.985.985 0 0 1 .288.696v1.262a2.984 2.984 0 0 0 2.984 2.984h1.262c.261 0 .512.104.696.288l.893.893a2.984 2.984 0 0 0 4.22 0l.893-.893a.985.985 0 0 1 .696-.288h1.262a2.984 2.984 0 0 0 2.984-2.984V15.7c0-.261.104-.512.288-.696l.893-.893a2.984 2.984 0 0 0 0-4.22l-.893-.893a.985.985 0 0 1-.288-.696V7.04a2.984 2.984 0 0 0-2.984-2.984h-1.262a.985.985 0 0 1-.696-.288l-.893-.893A2.984 2.984 0 0 0 12 2Zm3.683 7.73a1 1 0 1 0-1.414-1.413l-4.253 4.253-1.277-1.277a1 1 0 0 0-1.415 1.414l1.985 1.984a1 1 0 0 0 1.414 0l4.96-4.96Z" clipRule="evenodd"/>
                          </svg>

                          <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-white">Thank you for signing up. Please check your email <span className="font-semibold">({signUpEmail})</span> to verify your account.</h3>
                      </div>
                  </div>
              </div>
          </div>
          <div className="bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40"></div>
        </>
      )}
      <form autoComplete="off">
      <Flex className="min-h-screen" alignItems="center" justifyContent="center">
        <Box className="w-full max-w-lg m-auto" color="neutral800" padding={10} background="neutral0">
          <FormImage src="/assets/images/tc_icon_pink.png" alt="Workflow"/>
          <Typography as="h2" textAlign="center" className="mt-2" variant="alpha">Welcome to Trueclaim</Typography>
          <Typography as="p" variant="epsilon" textAlign="center"  textColor="neutral600" className="mt-2">Create new account</Typography>
          <Box marginTop={6}>
            {signUpStatus?.type === 'error' && (
              <Box marginBottom={6}>
              <Alert className="mt-6" closeLabel="Close" variant="danger">
                {error.message}
              </Alert>
              </Box>
            )}

            <Grid marginBottom={6} gap={5}>
              <GridItem col={6}>
                  <TextInput type="text" 
                              label="First Name" 
                              required
                              name="firstName" 
                              onChange={handleInputChange}
                              value={formData.firstName}
                              error={formError.firstName && "Please enter first name"} />
              </GridItem>
              <GridItem col={6}>
                <TextInput type="text" 
                              label="Last Name" 
                              required
                              name="lastName" 
                              onChange={handleInputChange}
                              value={formData.lastName}
                              error={formError.lastName && "Please enter last name"} />
              </GridItem>
            </Grid>
            <Box marginBottom={6}>
              {formData.customerJobs === "CO" ? (
                <TextInput marginBottom={6}  type="text" 
                    label="Company" 
                    name="company" 
                    id="company"
                    onChange={handleInputChange}
                    error={formError.company && "Please enter company name."}
                    value={formData.company} />
              ) : (
                <TextInput marginBottom={6}  type="text" 
                    label="Company" 
                    name="company" 
                    id="company"
                    required
                    onChange={handleInputChange}
                    error={formError.company && "Please enter company name."}
                    value={formData.company} />
              )}
            </Box>

            <Box marginBottom={6}>
              <SingleSelect
                label="I am"
                name="customerJobs"
                onChange={setSingleSelection}
                value={formData.customerJobs}
                error={formError.customerJobs && "Please select an option"}>
                  <SingleSelectOption value="AGG">Aggregator</SingleSelectOption>
                  <SingleSelectOption value="BS">Repair Facility</SingleSelectOption>
                  <SingleSelectOption value="CO">Car Owner</SingleSelectOption>
              </SingleSelect>
            </Box>

            <Flex direction="column" alignItems="stretch" gap={6}>
              <TextInput type="text" 
                  label="Phone Number" 
                  name="phoneNumber" 
                  placeholder="Enter your phone number"
                  onChange={handleInputChange}
                  value={formData.phoneNumber}
                  error={formError.phoneNumber && "Please provide a valid phone number."} />

              <TextInput type="text" 
                  label="Email" 
                  name="email" 
                  placeholder="Enter your email address"
                  autocomple="off"
                  required
                  onChange={handleInputChange}
                  value={formData.email}
                  error={formError.email && "Please provide a valid email."} />

              <TextInput type="password"
                  label="Password"
                  name="password"
                  placeholder="Enter your password"
                  autocomple="off"
                  required
                  onChange={handleInputChange}
                  value={formData.password}
                  error={formError.password && "Password must be at least 6 characters"} />

              <TextInput type="password"
                  label="Confirm Password"
                  name="confirmPassword"
                  placeholder="Confirm your password"
                  autocomple="off"
                  required
                  onChange={handleInputChange}
                  value={formData.confirmPassword}
                  error={formError.confirmPassword && "Passwords do not match"} />

              <Checkbox
                name="terms"
                onChange={setTerms}
                value={formData.terms}
                error={formError.terms && "Please agree to the terms and conditions"}>
                I agree to the <Link onClick={() => setIsVisible(prev => !prev)}>term and conditions</Link>
              </Checkbox>


              <Button fullWidth onClick={hadleFormSubmit} disabled={loading}>Register</Button>
              <Flex justifyContent="flex-end">
                <Link to="/">Go back</Link>
              </Flex>
            </Flex>
          </Box>
          {isVisible && <ModalLayout onClose={() => setIsVisible(prev => !prev)} labelledBy="title">
            <ModalHeader>
              <Typography fontWeight="bold" textColor="neutral800" as="h2" id="title">
                Terms and Conditions
              </Typography>
            </ModalHeader>
            <ModalBody>
              <Box className="terms-and-conditions">
                {isContentLoading ? (<Typography>Loading...</Typography>)
                  : (termsContent && termsContent.length) && <BlocksRenderer content={termsContent} />
                }
              </Box>
            </ModalBody>
          </ModalLayout>}
        </Box>
      </Flex>
      </form>
    </>
  );
}
