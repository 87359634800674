import React, { useRef  } from "react";
import Button from '../../styled/base/Button/Button'
import classNames from 'classnames';
import { baseUrl } from "../../config";

export function ChatList({
  messages
}) {
  const messagesContainerRef = useRef(null);

  React.useEffect(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }
  }, [messages]);

  const optionsSelector = (options, action) => {
    return (
      <div className="grid grid-cols-2 gap-2 w-full">
        {options.map((option, index) => (
          <Button
            key={index}
            variant="secondary"
          >
            {option}
          </Button>
        ))}
      </div>
    );
  };

  const positionsSelector = (options) => {
    return (
      <div className="grid grid-cols-2 gap-2 w-full">
        {options.map((option, index) => (
          <Button
            key={index}
            variant="secondary"
          >
            {option}
          </Button>
        ))}
      </div>
    );
  };

  return (
    <div className="w-full h-full flex flex-col relative">
      <div
        className="overflow-y-auto overflow-x-hidden"
        ref={messagesContainerRef}>
          {messages?.map((message, index) => (
            <div
              key={index}
              className={classNames("flex flex-col gap-2 p-2 whitespace-pre-wrap", message.name === "Driver" ? "items-end" : "items-start")}
            >
              {!message.type && (
                <div className="flex gap-3 items-center max-w-[75%]">
                  <span
                    className={classNames("p-4 rounded-md", message.name === "Driver" ? "bg-primary text-primary-foreground" : "", message.name === "Bot" && !message.type ? "bg-chat-bot" : "")}
                  >
                    {message.message}
                  </span>
                </div>
              )}
              {message.type === "image" && (
                <div className="w-[75%]">
                  <div
                    className={
                      message.src.length > 1
                        ? "grid grid-cols-2 gap-2 md:grid-cols-3"
                        : "grid grid-cols-1"
                    }
                  >
                    {message.src.map((src, index) => (
                      <img
                        key={index}
                        src={src}
                        alt=""
                        fill
                        className="rounded-md object-cover border border-gray-300"
                      />
                    ))}
                  </div>
                </div>
              )}
              {message.options && message.options.length > 0 && (
                <div className="flex gap-3 items-center w-[75%]">
                  {optionsSelector(message.options, message.action)}
                </div>
              )}
              {message.positions && message.positions.length > 0 && (
                <div className="flex gap-3 items-center w-[75%]">
                  {positionsSelector(message.positions)}
                </div>
              )}
              {message.type === "image-message" && (
                <div className="flex gap-3 items-center max-w-[75%]">
                  <span className={"px-3 py-2 rounded-md bg-muted"}>
                    <img
                      src={`${baseUrl}${message.src}`}
                      alt=""
                      className=""
                      style={{ maxWidth: "100%" }}
                    />
                    {message.message}
                  </span>
                </div>
              )}
            </div>
          ))}
      </div>
    </div>
  );
}
