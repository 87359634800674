import { useLocation } from 'react-router-dom';
import React, { useContext, useState, useRef, useEffect } from 'react';
import socket from "../socketio";
import { GlobalContextState, GlobalContextDispatch } from "../context/globalContext";
import { baseUrl } from "../config";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function Header({ leftNavVisible, setLeftNavVisible, menuActive }) {
  const query = useQuery();
  const [searchParam, setSearchParam] = useState(query.get('search') || '');
  const dropdownRef = useRef(null);
  const menuIntergrationRef = useRef(null);
  const user = useContext(GlobalContextState).user;
  const token = useContext(GlobalContextState).token;
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [menuIntergrationActive, setMenuIntergrationActive] = useState(false);
  const [isChrome, setIsChrome] = useState(false);
  const [currentTokens, setCurrentTokens] = React.useState(0);
  const [estimaticAutomation, setEstimaticAutomation] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    const chrome = /Chrome/.test(userAgent) && /Google Inc/.test(window.navigator.vendor);
    setIsChrome(chrome);

    const initFetchData = async () => {
      await fectUserData();
    };
    initFetchData();
  }, []);

  useEffect(() => {
    if(menuActive === 'intergrations') {
      setMenuIntergrationActive(true);
    }
  }, [menuActive]);

  const dispatch = useContext(GlobalContextDispatch);

  useEffect(() => {
    if(user) {
      socket.on(`user-automation-updated-${user.id}`, (data) => {
        console.log('User automation updated', data);
        const updatedUser = {
          ...user,
          automation: data.automation
        };
        dispatch({ type: "LOGIN", payload: { jwt: token, user: updatedUser } });
        setEstimaticAutomation(data.automation);
      });

      socket.on(`user-send-to-estimatic-updated-${user.id}`, (data) => {
        console.log('User send to estimatic updated', data);
        const updatedUser = {
          ...user,
          sendToEstimatic: data.sendToEstimatic
        };
        dispatch({ type: "LOGIN", payload: { jwt: token, user: updatedUser } });
      });

      socket.on(`user-subscription-updated-${user.id}`, (data) => {
        console.log('User subscription updated', data);
        const updatedUser = {
          ...user,
          subscription: data.subscription
        };
        dispatch({ type: "LOGIN", payload: { jwt: token, user: updatedUser } });
        setIsSubscribed(data.subscription);
      });

      socket.on(`user-tokens-updated-${user.id}`, (data) => {
        console.log('User tokens updated', data);
        const updatedUser = {
          ...user,
          tcTokens: data.tcTokens
        };
        dispatch({ type: "LOGIN", payload: { jwt: token, user: updatedUser } });
        setCurrentTokens(data.tcTokens);
      });
      
      return () => {
        socket.off(`user-automation-updated-${user.id}`);
        socket.off(`user-subscription-updated-${user.id}`);
        socket.off(`user-tokens-updated-${user.id}`);
        socket.off(`user-send-to-estimatic-updated-${user.id}`);
      }
    }
  }, [user, token, dispatch]);

  async function fectUserData() {
    try {
      const response = await fetch(`${baseUrl}/api/users/me`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        dispatch({ type: "LOGIN", payload: { jwt: token, user: data } });
        setIsSubscribed(data.subscription);
        setEstimaticAutomation(data.automation);
        setCurrentTokens(data.tcTokens);
      }
    } catch (error) {
      console.error('Failed to fetch data', error);
    }
  }

  // Toggle dropdown visibility
  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const toggleMenuIntergration = () => {
    setMenuIntergrationActive(!menuIntergrationActive);
  };
  
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownVisible(false);
    }

    if (menuIntergrationRef.current && !menuIntergrationRef.current.contains(event.target)) {
      setMenuIntergrationActive(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const clearSearch = () => {
    window.location.href = '/claims';
  }

  const handleCreateClaim = () => {
    window.location.href = '/claims/create';
  }

  return (
    <>
      <nav className={`${leftNavVisible? 'ml-64' : ''} transition-all fixed top-0 z-50 right-0 left-0 bg-gray-900 border-b border-[#222] border-solid`}>
        <div className="flex flex-wrap items-center justify-between mx-auto py-4 px-14">
          <div className="flex items-center space-x-4">
            {isSubscribed && (
              <button
                id="navbar-toggle"
                data-collapse-toggle="navbar-user"
                type="button"
                className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg dark:hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 bg-gray-700 dark:focus:ring-gray-600"
                aria-controls="navbar-user"
                aria-expanded="false"
                onClick={() => setLeftNavVisible(!leftNavVisible)}
              >
                {!leftNavVisible ? (
                  <svg
                    className="w-5 h-5"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 17 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M1 1h15M1 7h15M1 13h15"
                    />
                  </svg>
                ) : (
                  <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18 17.94 6M18 18 6.06 6"/>
                  </svg>
                )}
              </button>
            )}
            <a href="/">
              <img
                src="/assets/images/tc_logo_white-49bdf314.png"
                className="h-8"
                alt="Trueclaim App"
              />
            </a>
          </div>
          <div className="flex items-center space-x-4">
            {isSubscribed && (
              <button 
                onClick={handleCreateClaim}
                type="button"
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                  <svg className="w-4 h-4 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 12h14m-7 7V5"/>  
                  </svg>
                  New Claim
              </button>
            )}
            {isSubscribed && (
              <form className="flex w-80" method="GET" action="/claims">
                <input type="text" id="default-search" 
                  name="search"
                  value={searchParam}
                  onChange={(e) => setSearchParam(e.target.value)}
                  className="block w-full h-10 text-sm text-gray-900 border border-gray-300 rounded-l-lg bg-gray-50 focus:ring-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white" 
                  placeholder="Search VIN, Vehicle , Id" required />

                {searchParam && (
                  <button type="button" 
                    onClick={() => clearSearch()}
                    className="text-white h-10 bg-gray-600 hover:bg-gray-700 text-sm px-4">
                    <svg className="w-4 h-4 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18 17.94 6M18 18 6.06 6"/>
                    </svg>
                  </button>
                )}
                <button type="submit" 
                  disabled={searchParam.length < 4}
                  className={`text-white ${
                    searchParam.length < 4
                      ? 'bg-gray-400 cursor-not-allowed'
                      : 'bg-blue-700 hover:bg-blue-800 dark:bg-blue-600 dark:hover:bg-blue-700'
                  } focus:outline-none rounded-r-lg text-sm px-4 h-10`}>
                  <svg className="w-4 h-4 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                  </svg>
                </button>
              </form>
            )}
            <div className="flex items-center md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse relative z-50">
              <button
                type="button"
                className="flex text-sm bg-gray-800 md:me-0 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600 rounded-lg"
                id="user-menu-button"
                aria-expanded={dropdownVisible}
                onClick={toggleDropdown}
              >
                <span className="sr-only">Open user menu</span>
                <span 
                  className={`h-10 flex items-center justify-center text-sm text-white bg-trueclaim px-4 rounded-lg`}>
                    {user && user.username}
                  <svg className="w-4 h-4 ml-2 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 9-7 7-7-7"/>
                  </svg>

                </span>
              </button>
              <div
                ref={dropdownRef}
                className={`z-50 ${dropdownVisible ? 'block' : 'hidden'} w-52 text-base list-none bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600 absolute right-0 top-full mt-2`}
                id="user-dropdown"
              >
                <div className="px-4 py-3 border-b border-gray-100 dark:border-gray-600 border-solid">
                  <span className="block text-sm text-gray-900 dark:text-white">
                    {currentTokens > 0 ? `${currentTokens} tokens` : `${currentTokens} token`}
                  </span>
                  <span className="block text-sm text-gray-500 truncate dark:text-gray-400">
                    {user && user.email}
                  </span>
                </div>
                <ul className="py-2" aria-labelledby="user-menu-button">
                  <li>
                    <a
                      href="/settings/update-profile"
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                    >
                      Update Profile
                    </a>
                  </li>
                  <li>
                    <a
                      href="/settings/update-password"
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                    >
                      Change Password
                    </a>
                  </li>
                  <li>
                    <a
                      href="/settings/subscription"
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                    >
                      Subscription & Payments
                    </a>
                  </li>
                  <li>
                    <a
                      href="/settings/token-history"
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                    >
                      Account Statement
                    </a>
                  </li>
                  <li>
                    <a
                      href="/logout"
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                    >
                      Sign out
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <aside
        id="logo-sidebar"
        className={`${leftNavVisible ? 'translate-x-0' : '-translate-x-full'} fixed top-0 left-0 z-50 w-64 -mt-px h-screen pt-[73px] transition-transform border-r border-gray-200 dark:bg-gray-900 dark:border-gray-700 border-r border-[#3e3e3e] border-solid`}
        aria-label="Sidebar"
      >
        <div className="h-full px-3 pb-4 overflow-y-auto">
          <ul className="space-y-2 font-medium">
            {user.superAdmin && (
              <li>
                <a
                  href="/dashboard"
                  className={`${menuActive === 'dashboard' ? 'bg-gray-100 dark:bg-gray-700' : ''} flex items-center px-2 py-4 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group`}
                >
                  <span className="ms-3">Dashboard</span>
                </a>
              </li>
            )}
            {isSubscribed && (
              <li>
                <a
                  href="/claims"
                  className={`${menuActive === 'claims' ? 'bg-gray-100 dark:bg-gray-700' : ''} flex items-center px-2 py-4 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group`}
                >
                  <span className="ms-3">Claims</span>
                </a>
              </li>
            )}
            {isSubscribed && (
              <li>
                <a
                  href="/settings"
                  className={`${menuActive === 'settings' ? 'bg-gray-100 dark:bg-gray-700' : ''} flex items-center px-2 py-4 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group`}
                >
                  <span className="ms-3">Settings</span>
                </a>
              </li>
            )}
            {estimaticAutomation && (
              <li ref={menuIntergrationRef}>
                <button type="button" 
                  onClick={toggleMenuIntergration}
                  className={`${menuActive === 'intergrations' ? 'bg-gray-100 dark:bg-gray-700' : ''} flex items-center px-2 py-4 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group w-full`}
                  aria-controls="dropdown-example" data-collapse-toggle="dropdown-example">
                  <span className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap">Intergrations</span>
                  {menuIntergrationActive || menuActive === 'intergrations' ? (
                    <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m16 14-4-4-4 4"/>
                    </svg>
                  ) : (
                    <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m8 10 4 4 4-4"/>
                    </svg>
                  )}
                </button>
                <ul id="dropdown-example" className={`${menuIntergrationActive || menuActive === 'intergrations' ? 'block' : 'hidden'}  py-2 space-y-2`}>
                    <li>
                      <a href="/intergrations/config-mitchell" className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">Mitchell</a>
                    </li>
                    <li>
                      <a href="/intergrations/config-ccc" className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">CCC</a>
                    </li>
                </ul>
              </li>
            )}
            <li>
              <a
                href="/documents"
                className={`${menuActive === 'documents' ? 'bg-gray-100 dark:bg-gray-700' : ''} flex items-center px-2 py-4 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group`}
              >
                <span className="ms-3">Documents</span>
              </a>
            </li>
            {isChrome && (
              <li>
                <a
                  href="https://chromewebstore.google.com/detail/trueclaimai/egepamljlmjcnnhbenimmbopbfpbecee"
                  target="_blank"
                  className="flex items-center px-2 py-4 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                >
                  <span className="ms-3">Download Extension</span>
                </a>
              </li>
            )}
          </ul>
        </div>
      </aside>

    </>
    
  );
}