import React from "react";

const AdasSkeleton = () => {
  return (
    <div className="leading-6 dark">
      <div className="lg:m-4 block items-center justify-center rounded-lg border-2 border-gray-200 bg-gray-100 bg-strapi-neutral-800  p-6 animate-pulse">
        <div className="flex grow flex-col">
          <div className="flex items-center justify-between">
            <div className="flex flex-wrap items-center justify-start text-sm md:flex">
              <span className="mb-2 mr-2">
                <div className="h-8 bg-gray-200 rounded-lg dark:bg-gray-700 w-32"></div>
              </span>
              <span className="mb-2 mr-2">
                <div className="h-8 bg-gray-200 rounded-lg dark:bg-gray-700 w-32"></div>
              </span>
              <span className="mb-2 mr-2">
                <div className="h-8 bg-gray-200 rounded-lg dark:bg-gray-700 w-32"></div>
              </span>
              <span className="mb-2 mr-2">
                <div className="h-8 bg-gray-200 rounded-lg dark:bg-gray-700 w-32"></div>
              </span>
            </div>
            <div className="rounded-lg border-2 bg-gray-700 p-5 md:max-w-xs">
              <div className="flex items-center pt-2">
                <label className="opacity-0 relative inline-flex cursor-pointer">
                  <input
                    className="peer sr-only"
                    type="checkbox"
                  />
                  <div className="peer h-4 w-9 rounded-full bg-gray-300 after:absolute after:bottom-[2px] after:left-[2px] after:right-[2px] after:top-[2px] after:h-3 after:w-4 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800"></div>
                </label>
                <label
                  htmlFor="checked-checkbox"
                  className="opacity-0 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Vehicle has sustained collision damage
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="sticky-parent" className="flex-row lg:px-2 md:flex">
        <div className="cursor-pointer lg:mx-2 my-4 block flex-1 rounded-lg border border-gray-200 bg-white p-6 shadow hover:bg-neutral-500 dark:border-gray-700 bg-strapi-neutral-800 dark:hover:bg-neutral-500 md:mb-0">
          <div className="h-12 bg-gray-200 rounded-lg dark:bg-gray-700 w-16"></div>
          <div className="h-6 bg-gray-200 rounded-lg dark:bg-gray-700 w-48 mt-2"></div>
        </div>
        <div className="cursor-pointer lg:mx-2 my-4 block flex-1 rounded-lg border border-gray-200 bg-white p-6 shadow hover:bg-neutral-500 dark:border-gray-700 bg-strapi-neutral-800 dark:hover:bg-neutral-500 md:mb-0">
          <div className="h-12 bg-gray-200 rounded-lg dark:bg-gray-700 w-16"></div>
          <div className="h-6 bg-gray-200 rounded-lg dark:bg-gray-700 w-48 mt-2"></div>
        </div>
        <div className="cursor-pointer lg:mx-2 my-4 block flex-1 rounded-lg border border-gray-200 bg-white p-6 shadow hover:bg-neutral-500 dark:border-gray-700 bg-strapi-neutral-800 dark:hover:bg-neutral-500 md:mb-0">
          <div className="h-12 bg-gray-200 rounded-lg dark:bg-gray-700 w-16"></div>
          <div className="h-6 bg-gray-200 rounded-lg dark:bg-gray-700 w-48 mt-2"></div>
        </div>
        <div className="cursor-pointer lg:mx-2 my-4 block flex-1 rounded-lg border border-gray-200 bg-white p-6 shadow hover:bg-neutral-500 dark:border-gray-700 bg-strapi-neutral-800 dark:hover:bg-neutral-500 md:mb-0">
          <div className="h-12 bg-gray-200 rounded-lg dark:bg-gray-700 w-16"></div>
          <div className="h-6 bg-gray-200 rounded-lg dark:bg-gray-700 w-48 mt-2"></div>
        </div>
        <div className="cursor-pointer lg:mx-2 my-4 block flex-1 rounded-lg border border-gray-200 bg-white p-6 shadow hover:bg-neutral-500 dark:border-gray-700 bg-strapi-neutral-800 dark:hover:bg-neutral-500 md:mb-0">
          <div className="h-12 bg-gray-200 rounded-lg dark:bg-gray-700 w-16"></div>
          <div className="h-6 bg-gray-200 rounded-lg dark:bg-gray-700 w-48 mt-2"></div>
        </div>
      </div>
      <div className="lg:m-4 block items-center justify-center rounded-lg border-2 border-gray-200 bg-gray-100 bg-strapi-neutral-800  p-6 animate-pulse">
        <h4 className="mb-2 max-w-full text-2xl font-bold tracking-tight text-gray-900 dark:text-white">ADAS Systems</h4>
        <div className="flex-row md:flex">
          <ul className="max-w-full flex-1 list-inside list-disc space-y-1 text-gray-500 dark:text-gray-400 md:mr-2">
            {Array.from(Array(10).keys()).map((index) => {
              const randomWidth = `${Math.floor(Math.random() * (65 - 35 + 1) + 50)}%`;
              return (
                <li key={index}>
                  <span className="inline-block h-2.5 bg-gray-300 rounded-full dark:bg-gray-600" style={{ width: randomWidth }}></span>
                </li>
              );
            })}
          </ul>
          <ul className="max-w-full flex-1 list-inside list-disc space-y-1 text-gray-500 dark:text-gray-400 md:ml-2">
            {Array.from(Array(10).keys()).map((index) => {
              const randomWidth = `${Math.floor(Math.random() * (65 - 35 + 1) + 50)}%`;
              return (
                <li key={index}>
                  <span className="inline-block h-2.5 bg-gray-300 rounded-full dark:bg-gray-600" style={{ width: randomWidth }}></span>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AdasSkeleton;
