import io from 'socket.io-client';
import { baseUrl } from "./config";

const socket = io(baseUrl, {
  transports: ['websocket']
});

socket.on('connect', () => {
  console.log('Connected to Trueclaim Websocket');
});

socket.on('disconnect', (reason) => {
  console.log('Disconnected from Trueclaim Websocket:', reason);
});

socket.on('error', (error) => {
  console.error('Socket error:', error);
});

export default socket;