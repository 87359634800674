import { Switch, Route } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import { GlobalContextState } from "../context/globalContext";
import { Box } from "@strapi/design-system";
import ClaimDetails from "./ClaimDetails";
import { Redirect } from "react-router-dom";
import ClaimsList from '../components/Claim/ClaimsList';
import Create from '../components/Claim/Create';
import Header from "../components/Header";

export default function Claims() {
  const [leftNavVisible, setLeftNavVisible] = useState(false);
  const menuActive = 'claims';
  const user = useContext(GlobalContextState).user;
  const token  = useContext(GlobalContextState).token;

  useEffect(() => {
    const script = document.createElement('script');
    if(user){
      let company = '';
      if(user.mitchellName){
        company = user.mitchellName;
      }
      if(user.company && !user.mitchellName){
        company = user.company;
      }

      const credentials = {
        token: token,
        user: {
            email: user.email,
            username: user.username,
            id: user.id,
            type: user.superAdmin,
            mitchellId: user.mitchellId,
            mitchellName: company.replace(/'/g, ''),
        }
      }

      const scriptText = document.createTextNode(`
        chrome.runtime.sendMessage(
          'egepamljlmjcnnhbenimmbopbfpbecee',
          { type: 'logged-in', data: '${JSON.stringify(credentials)}' },
          { includeTlsChannelId: true },
           function(response) {
            console.log('response', response);
           }
        );
      `);
      script.appendChild(scriptText);
      document.body.appendChild(script);
    }
  }, []);

  const setLeftNav = (value) => {
    setLeftNavVisible(value);

    const stickyChild = document.getElementById('sticky-child');
    if(stickyChild){
      // Toggle class ml-64 on sticky-child
      if(value){
        stickyChild.classList.add('ml-64');
      } else {
        stickyChild.classList.remove('ml-64');
      }
    }
  }

  return (
    <Box className="dark bg-gray-900">
      <Header 
        leftNavVisible={leftNavVisible} 
        setLeftNavVisible={setLeftNav} 
        menuActive={menuActive}
      />
      <main className={`${leftNavVisible? 'ml-64' : ''} transition-all flex-1 pt-[73px]`}>
        <Switch>
          <Route 
            exact 
            path="/claims" 
            render={() => <ClaimsList />} />
          <Route
            path="/claims/create"
            render={() => <Create />}
          />
          <Route
            path="/claims/claim-details/:urlkey"
            render={() => <ClaimDetails />}
          />
        </Switch>
      </main>
    </Box>
  );
}
