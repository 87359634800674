import { useState, useContext, useEffect } from "react";
import { baseUrl } from '../config';
import {
  GlobalContextDispatch,
} from "../context/globalContext";
import useFetchMutation from '../hooks/useFetchMutation';
import {
  FormWrapper,
  FormContainer,
  FormImage,
  FormBox,
  FormHeading,
  FormError,
} from "../styled/styles/form";
import Button from "../styled/base/Button/Button";
import Input from "../styled/base/Input/Input";
import Page from "../styled/base/Page/Page";
import Heading from "../components/Heading/Heading";
import Grid from "../styled/layout/Grid/Grid";
import GridItem from "../styled/layout/Grid/GridItem";

const resetPasswordUrl = `${baseUrl}/api/auth/reset-password`;

const INITIAL_FORM_DATA = {
  code: "",
  password: "",
  passwordConfirmation: "",
};

const INITIAL_FORM_ERRORS = {
  code: false,
  password: false,
  passwordConfirmation: false,
};

export default function SignupForm() {

  const dispatch = useContext(GlobalContextDispatch);

  const [resetPassword, { data, loading, error} ] = useFetchMutation(resetPasswordUrl);

  const [formData, setFormData] = useState(INITIAL_FORM_DATA);
  const [formError, setFormError] = useState(INITIAL_FORM_ERRORS);

  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get("code");

  // If code is not present, redirect to signin\
  useEffect(() => {
    if (!code) {
      window.location.replace("/");
    }else {
      setFormData({ ...formData, code: code });
    }

    if (data && data.user) {
      window.location.replace("/?reset=success");
    }
  }, [code, data, dispatch]);

  function handleInputChange(event) {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  }

  function validatePassword(password) {
    if (password.length <= 6) {
      setFormError((prevState) => ({ ...prevState, password: true }));
      return true;
    } else {
      setFormError((prevState) => ({ ...prevState, password: false }));
      return false;
    }
  }

  function validateConfirmPassword(passwordConfirmation) {
    // get password from #password input
    var password = document.getElementById("password").value;
    if (password !== passwordConfirmation) {
      setFormError((prevState) => ({ ...prevState, passwordConfirmation: true }));
      return true;
    } else {
      setFormError((prevState) => ({ ...prevState, passwordConfirmation: false }));
      return false;
    }
  }

  function formValidation(formData) {
    let hasError = 0;
    hasError += validatePassword(formData.password) ? 1 : 0;
    hasError += validateConfirmPassword(formData.passwordConfirmation) ? 1 : 0;
    return hasError;
  }

  function hadleFormSubmit(event) {
    event.preventDefault();
    const hasErrors = formValidation(formData);

    if (!hasErrors) {
      const registerPayload = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      };

      resetPassword(registerPayload)
    }
  }

  if (error) return <h1>OOPPS!</h1>

  return (
    <Page className="bg-gray-800">
      <main className="h-full flex justify-center content-center">
        <Grid>
          <GridItem>
            <Heading
              heading="Welcome to Trueclaim"
              subheading=""
            />
          </GridItem>

          <GridItem>
            <FormWrapper>
              <FormBox className="mt-8">
                <FormContainer>
                  <form onSubmit={hadleFormSubmit}>
                    <fieldset className="space-y-6" disabled={loading}>
                      <FormBox>
                        <FormImage
                          src="/assets/images/tc_icon_pink.png"
                          alt="Workflow"
                        />
                        <FormHeading>Reset Your Password</FormHeading>
                      </FormBox>

                      <input type="hidden" name="code" value={code} />

                      <Input
                        id="password"
                        name="password"
                        type="password"
                        label="Password"
                        autoComplete="new-password"
                        placeholder="New password"
                        onBlur={(e) => validatePassword(e.target.value)}
                        value={formData.password}
                        error={
                          formError.password && "Password must be at least 6 characters"
                        }
                        onChange={handleInputChange}
                      />

                      <Input
                        id="confirmPassword"
                        name="passwordConfirmation"
                        type="password"
                        label="Confirm Password"
                        autoComplete="new-password"
                        placeholder="Confirm password"
                        onBlur={(e) => validateConfirmPassword(e.target.value)}
                        value={formData.passwordConfirmation}
                        onChange={handleInputChange}
                        error={
                          formError.passwordConfirmation && "Passwords do not match"
                        }
                      />

                      <Button className="mt-6 mb-6" type="submit">
                        Change Password
                      </Button>
                    </fieldset>
                  </form>

                  <FormError></FormError>
                </FormContainer>
              </FormBox>
            </FormWrapper>
          </GridItem>
        </Grid>
      </main>
    </Page>

  );
}
